<template>
  <v-container class="warehouse-province" v-if="province">
    <h1 class="text-center">
      {{ $t("warehouse.province.title", { province: province.name }) }}
    </h1>
    <p class="text-center">
      {{ $t("warehouse.province.subtitle", { province: province.name }) }}
    </p>
    <GmapMap
      ref="mapRef"
      :center="{
        lat: 45.410119194768654,
        lng: 11.881289090441028
      }"
      :zoom="8"
      :style="{ width: mapWidth, height: mapHeight }"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: false
      }"
      class="warehouse-province-map mb-6"
    >
      <GmapMarker
        :ref="'warehousMarker_' + warehouse.warehouseId"
        v-for="(warehouse, i) in warehouses"
        :key="warehouse.warehouseId"
        :position="
          google &&
            new google.maps.LatLng(
              warehouse.address.latitude,
              warehouse.address.longitude
            )
        "
        :clickable="true"
        icon="/img_layout/map/negozi.png"
        @click="toggleInfoWindow(warehouse, i)"
      />
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick.stop="infoWinOpen = false"
      >
        {{ selectedWarehouse }}
        Contenuto
      </gmap-info-window>
    </GmapMap>
    <v-row class="warehouses">
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="warehouse in warehouses"
        :key="warehouse.warehouseId"
      >
        <v-card outlined class="warehouse-card rounded-md px-1 pb-3">
          <v-card-title
            ><h4 class="primary--text font-weight-bold text-uppercase">
              {{ warehouse.name }}
            </h4></v-card-title
          >
          <v-card-text class="warehouse-card-address">
            <address>
              {{ warehouse.address.address1 }},
              {{ warehouse.address.addressNumber }}<br />
              {{ warehouse.address.city }} ({{ warehouse.address.province }})
            </address>
          </v-card-text>
          <v-card-actions>
            <v-btn
              elevation="0"
              x-large
              block
              class="primary"
              :to="`/negozi-e-volantini/negozi/${warehouse.warehouseId}`"
              >{{ $t("warehouse.province.cardBtn") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center mt-4">
      <h2 class="text-center">{{ $t("warehouse.province.subtitle2") }}</h2>
      <h4
        class="d-inline px-2 mr-n3"
        v-for="(province, i) in provinces"
        :key="province.categoryId"
      >
        <router-link class="province-link" :to="`/${province.slug}`">
          {{ province.name }}</router-link
        >
        {{ printPipe(provinces, i) }}
      </h4>
    </div>
  </v-container>
</template>
<style lang="scss">
.warehouse-province {
  .warehouse-card {
    .warehouse-card-address {
      address {
        font-size: initial;
        font-style: initial;
        font-weight: initial;
      }
    }
  }
  .province-link {
    text-decoration: none;
  }
  .warehouse-province-map .vue-map {
    border: 1px solid var(--v-grey-lighten1);
    border-radius: 12px;
  }
}
</style>
<script>
import categoryMixins from "~/mixins/category";
import addressService from "@/commons/service/addressService";
import categoryService from "@/commons/service/categoryService";
import { gmapApi } from "vue2-google-maps";

export default {
  name: "WarehouseProvince",
  mixins: [categoryMixins],
  data() {
    return {
      province: null,
      warehouses: null,
      provinces: null,
      infoOptions: {
        content: "",
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoWindowPos: null,
      infoWinOpen: false,
      selectedWarehouse: null
    };
  },

  methods: {
    async getWarehousesByProvince(provinceId) {
      const searchParams = {
        parent_province_id: provinceId
      };
      let data = await addressService.findWarehouse(searchParams);
      this.warehouses = data.warehouses;
    },
    async getProvinces() {
      try {
        let result = await categoryService.getCategoryBySlug(
          "negozi-e-volantini"
        );
        this.provinces = result.children.filter(
          province => province.categoryId !== this.province.categoryId
        );
      } catch (err) {
        console.log(err);
      }
    },
    printPipe(provinces, i) {
      return i < provinces.length - 1 ? "|" : null;
    },
    toggleInfoWindow(warehouse) {
      this.infoWindowPos = {
        lat: warehouse.address.latitude,
        lng: warehouse.address.longitude
      };
      let phoneNumber = warehouse.metaData.warehouse_locator?.PHONE || " -- ";

      let infoWindowBtn = null;
      infoWindowBtn = `<a class='pl-0 v-btn v-btn--text primary--text v-size--small' target='system' href='https://www.google.it/maps/dir//${warehouse.name}@/${warehouse.address.latitude},${warehouse.address.longitude}'>
          COME ARRIVARE
        </a><span width="10px"></span> <a class='v-btn v-btn--has-bg theme--light primary default--text v-size--small' href='/${this.province.slug}/${warehouse.warehouseId}'>
          VAI ALLA SCHEDA
        </a>`;
      this.infoOptions.content = `<div id='infoWindowDialog' style='font-family: Open Sans, arial, sans-serif'>
          <h3 class='d-flex align-center mb-2' style='line-height: 1.2rem;'>
            ${warehouse.name || warehouse.address.addressName}
          </h3>
          <div class='mb-1'>
            ${warehouse.address.address1},${warehouse.address.addressNumber}
                ${warehouse.address.city} (${warehouse.address.province}) <br/>
            <strong>Telefono:</strong> ${phoneNumber} <br/>
          </div>
          ${infoWindowBtn}
        </div>`;

      //check if its the same marker that was selected if yes toggle
      if (
        this.selectedWarehouse &&
        this.selectedWarehouse.warehouseId == warehouse.warehouseId
      ) {
        this.infoWinOpen = !this.infoWinOpen;
      }

      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.selectedWarehouse = warehouse;
      }
    }
  },
  computed: {
    google: gmapApi,
    mapWidth() {
      return "auto";
    },
    mapHeight() {
      return this.$vuetify.breakpoint.xsOnly ? "300px" : "465px";
    }
  },
  async mounted() {
    this.province = this.category;
    await this.getProvinces();
    await this.getWarehousesByProvince(
      this.province.metaData.template_model.PROVINCE_ID
    );
  }
};
</script>
